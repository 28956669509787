//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    data() {
      return this.params.data;
    }
  }
});
