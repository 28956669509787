var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticClass: "flex",
          staticStyle: { padding: "2px 0" },
          attrs: { align: "middle", justify: "start" }
        },
        [
          !_vm.data.published
            ? _c(
                "a-popconfirm",
                {
                  attrs: {
                    placement: "top",
                    title: _vm.$t("Are you sure to restore this version?"),
                    "ok-text": _vm.$t("Yes"),
                    "cancel-text": _vm.$t("No")
                  },
                  on: { confirm: _vm.handleRevertVersion }
                },
                [
                  _c(
                    "a-button",
                    { staticClass: "mr-xs", attrs: { type: "primary" } },
                    [_vm._v(" " + _vm._s(_vm.$t("Restore")) + " ")]
                  )
                ],
                1
              )
            : _c(
                "span",
                {
                  staticClass: "font-bold mr-xs",
                  staticStyle: { "font-style": "italic" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Current revision")) + " ")]
              ),
          _c(
            "a-button",
            {
              staticStyle: { "margin-top": "2px" },
              on: { click: _vm.handlePreviewVersion }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Preview")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }