var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.visible,
            title: _vm.$t("Revisions for {name}", { name: _vm.page.name }),
            width: 800,
            "body-style": { padding: "10px" }
          },
          on: {
            cancel: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c("SGrid", {
                staticClass: "mb-lg",
                attrs: {
                  "grid-height": 600,
                  "grid-name": "revision-siteopts-grid",
                  loading: _vm.loading,
                  rowData: _vm.items,
                  columnDefs: _vm.columnDefs,
                  gridContext: _vm.context
                }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }