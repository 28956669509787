var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.visible,
            width: 700,
            "body-style": { padding: "10px" }
          },
          on: {
            cancel: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex mb-xs content-start" },
            [
              _c("a-icon", {
                staticClass: "mr-xs",
                attrs: { type: "info-circle" }
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "Optionally, you can add a note for this version for revision history."
                    )
                  ) +
                  " "
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex mb-xs" },
            [
              _c("a-textarea", {
                attrs: {
                  rows: 3,
                  placeholder: _vm.$t("This version is for ...")
                },
                model: {
                  value: _vm.published_note,
                  callback: function($$v) {
                    _vm.published_note = $$v
                  },
                  expression: "published_note"
                }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading,
                    loading: _vm.loading
                  },
                  on: { click: _vm.handlePublish }
                },
                [
                  _vm.page.ispublic
                    ? _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Save and Update")) + " ")
                      ])
                    : _c("span", [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }