//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      loading: false
    };
  },
  computed: {
    data() {
      return this.params.data;
    }
  },
  methods: {
    handlePreviewVersion() {
      this.params.context.componentParent.handlePreviewVersion(this.params.data);
    },
    handleRevertVersion() {
      this.params.context.componentParent.handleRevertVersion(this.params.data);
    }
  }
});
