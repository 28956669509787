//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      published_note: ''
    };
  },

  created() {},

  computed: {},

  methods: {
    handlePublish() {
      this.$emit('publish', { published_note: this.published_note });
    }
  }
};
