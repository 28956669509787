//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ActionsCellRenderer, StatusCellRenderer } from './grid';
import moment from 'moment';

export default {
  components: {
    // Grid
    ActionsCellRenderer,
    StatusCellRenderer
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      context: { componentParent: this },
      grid: null
    };
  },

  created() {},

  computed: {
    columnDefs() {
      return [
        {
          headerName: 'Revision',
          field: 'version',
          valueFormatter: ({ value }) => this.$t('Version {version}', { version: value }),
          width: 120,
          cellClass: 'text-ellipsis',
          lockPosition: true
        },
        {
          headerName: 'Note',
          field: 'published_note',
          cellClass: 'text-ellipsis',
          width: 300,
          lockPosition: true
        },
        {
          headerName: 'Status',
          field: 'project_public_record',
          width: 80,
          cellRenderer: 'StatusCellRenderer',
          lockPosition: true
        },
        {
          headerName: 'Date time',
          field: 'published_at',
          width: 120,
          valueFormatter: ({ value }) => moment(value).format('YYYY/MM/DD HH:mm'),
          lockPosition: true
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: 'ActionsCellRenderer',
          cellClass: 'text-ellipsis',
          width: 125,
          pinned: 'right',
          lockPosition: true
        }
      ];
    }
  },

  methods: {
    handlePreviewVersion(data) {
      this.$emit('preview', data);
    },
    handleRevertVersion(data) {
      this.$emit('revert', data);
    }
  }
};
