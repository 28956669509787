var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tag",
        {
          staticStyle: { margin: "0" },
          attrs: { color: _vm.data.project_public_record ? "green" : "red" }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(_vm.data.project_public_record ? "Public" : "Private")
              ) +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }